<template>
  <div class="view-container">
    <div class="group-content">
      <div class="group-list-container" ref="groupRef">
        <div class="group-list" v-for="(item, index) in groupList" :key="index">
          <div class="list-title" ref="titleRef">
            {{ item.title }}
          </div>
          <div class="brand-list-content">
            <div
              @click="to(`/classificationGoods?brandname=${item2.name}`)"
              v-for="(item2, index2) in item.lists"
              :key="index2"
              class="brand-img"
            >
              <img :src="item2.logo" alt="" />
              <div>{{ item2.name }}</div>
            </div>
          </div>
          <!-- <el-checkbox-group v-model="checkList">
            <el-checkbox
              :label="item1.name"
              v-for="(item1, index1) in item.children"
              :key="index1"
            />
          </el-checkbox-group> -->
        </div>
      </div>
      <div class="word-list">
        <div
          v-for="(item, index) in groupList"
          :key="index"
          @click="goIndex(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pinyin } from "pinyin-pro";
import { http } from "../../../request/http";

export default {
  data() {
    return {
      groupList: [],
      checkList: [],
      wordList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },
  mounted() {
    console.log(pinyin("汉语拼音"));
    this.groupList = [];
    this.wordList.forEach((item) => {
      this.groupList.push({ title: item, lists: [] });
    });
    this.getBrandList();
  },
  methods: {
    getBrandList() {
      http("goods.php", {
        fun: "getGoodsBrandList",
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let list = [];
          res.data.forEach((item) => {
            let pinyinArr = pinyin(item.name, {
              pattern: "first",
              toneType: "none",
              type: "array",
            });
            list.push({
              ...item,
              pinyin: pinyinArr[0].toUpperCase(),
            });
          });
          this.groupList.forEach((item) => {
            list.forEach((item2) => {
              if (item.title == item2.pinyin) {
                item.lists.push(item2);
              }
            });
          });
          this.groupList = this.groupList.filter(
            (item) => item.lists.length > 0
          );
          console.log(this.groupList);
        }
      });
    },
    goIndex(params) {
      const groupRef = this.$refs.groupRef;
      const titleRef = this.$refs.titleRef;
      titleRef.map((item) => {
        if (item.innerText === params) {
          groupRef.scrollTo({
            top: item.offsetTop,
            behavior: "smooth", // 平滑移动
          });
        }
      });
    },
    to(url) {
      this.$router.push(url);
    },
    // groupRef.scrollTop = offsetTop
  },
};
</script>

<style lang="scss" scoped>
.view-container {
  width: 1200px;
  margin: 0 auto;
  .group-content {
    height: 500px;
    position: relative;
    .group-list-container {
      height: 100%;
      overflow-x: auto;
      .group-list {
        margin-bottom: 16px;
        padding: 20px;
        .list-title {
          font-size: 14px;
          // font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #3e4451;
          margin-bottom: 16px;
        }
        // /deep/.el-checkbox {
        //   margin: 0 56px 16px 0;
        // }
      }
    }
    .word-list {
      position: absolute;
      right: 19px;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 500px;
      font-size: 12px;
      // font-family: $font;
      font-weight: 400;
      color: #3e4451;
      div {
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.brand-list-content {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .brand-img {
    width: 150px;
    text-align: center;
    font-size: 14px;
    margin-right: 20px;
    img {
      height: 150px;
      box-shadow: #666 0px 0px 10px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
